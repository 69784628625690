import React from 'react';
import PropTypes from 'prop-types';

class SocialLinks extends React.Component {
	static propTypes = {
		className: PropTypes.string,
		socialLinks: PropTypes.arrayOf(
			PropTypes.shape({
				name: PropTypes.string.isRequired,
				label: PropTypes.string.isRequired,
				url: PropTypes.string.isRequired,
				icon: PropTypes.string.isRequired,
			}),
		),
	};

	render() {
		const { className, socialLinks } = this.props;

		return (
			<ul className={className}>
				{socialLinks.map((item, index) => (
					<li
						className={`SocialLinks__item SocialLinks__item--${item.name}`}
						key={item.name}
					>
						<a
							className="Button"
							href={item.url}
							target="_blank"
							rel="noopener noreferrer"
						>
							{item.label} <i className={item.icon}></i>
						</a>
					</li>
				))}
			</ul>
		);
	}
}

export default SocialLinks;
