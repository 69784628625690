import React from 'react';
// import PropTypes from 'prop-types';

import './ForReview.scss';

const activeEnv =
	process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || 'development';

class ForReview extends React.Component {
	state = { review: 'loading' };

	componentDidMount() {
		this.setState(() => ({ review: 'wait' }));
		this.setState(() => ({
			amount: document.querySelectorAll('[data-needs-review]').length,
		}));
	}

	handleClick = () => {
		if ('wait' === this.state.review) {
			this.setState(() => ({ review: 'review' }));
		}
		if ('review' === this.state.review) {
			this.setState(() => ({ review: 'wait' }));
		}
	};

	render() {
		if ('production' === activeEnv) {
			return false;
		}

		return (
			<button
				className="ForReview"
				data-state={this.state.review}
				onClick={this.handleClick}
			>
				Review Mode
				{this.state.amount ? (
					<span className="ForReview__count">{this.state.amount}</span>
				) : (
					''
				)}
			</button>
		);
	}
}

export default ForReview;
