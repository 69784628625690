import React from 'react';
import PropTypes from 'prop-types';
// import { Link } from 'gatsby';

import { copyStringToClipboard } from './helpers';
import SocialLinks from './SocialLinks';

import './Footer.scss';

const social = [
	{
		name: 'facebook',
		label: 'Facebook',
		url: `${encodeURI(
			'https://www.facebook.com/sharer/sharer.php?u=https://ourfuturecoast.org',
		)}`,
		icon: 'fab fa-facebook-f',
	},
	{
		name: 'twitter',
		label: 'Twitter',
		url: `${encodeURI(
			'http://twitter.com/share?text=Did you hear about Louisiana’s coastal land loss crisis?&url=https://ourfuturecoast.org',
		)}`,
		icon: 'fab fa-twitter',
	},
	{
		name: 'linkedin',
		label: 'LinkedIn',

		url: `${encodeURI(
			'https://www.linkedin.com/shareArticle?mini=true&url=https://ourfuturecoast.org&title=Did you hear about Louisiana’s coastal land loss crisis?&summary=Louisiana’s wetlands lose a football field of land every 100 minutes — but that’s not the whole the story. Check out ENVISIONING OUR FUTURE COAST to learn how Louisiana can transform its coast and become the new global standard for innovation and sustainability.&source=ourfuturecoast.org',
		)}`,
		icon: 'fab fa-linkedin-in',
	},
	{
		name: 'email',
		label: 'Email',
		url: `${encodeURI(
			'mailto:?subject=Did you hear about Louisiana’s coastal land loss crisis?&body=Louisiana’s wetlands lose a football field of land every 100 minutes — but that’s not the whole the story. Check out Envisioning our future coast to learn how Louisiana can transform its coast and become the new global standard for innovation and sustainability. ourfuturecoast.org',
		)}`,
		icon: 'fal fa-envelope',
	},
];

const footerMenus = [
	{
		title: 'Coastal Restoration',
		links: [
			{
				label: 'Restore the Mississippi River Delta',
				url: 'http://mississippiriverdelta.org/',
			},
			{
				label: 'Envisioning the Future of the Louisiana Gulf Coast',
				url:
					'https://www.waltonfamilyfoundation.org/learning/envisioning-the-future-of-the-louisiana-gulf-coast',
			},
			{
				label: '2017 Coastal Master Plan',
				url: 'http://coastal.la.gov/our-plan/2017-coastal-master-plan/',
			},
			{
				label: 'Priority Projects for Restoring the Coast',
				url: 'http://mississippiriverdelta.org/priorityprojects/',
			},
		],
	},
	{
		title: 'Sediment Diversions',
		links: [
			{
				label: 'Sediment Diversions 101',
				url:
					'http://mississippiriverdelta.org/restoration-solutions/sediment-diversions/',
			},
			{
				label:
					'A Tale of Two Basins: Why One is Thriving While the Other is Dying',
				url:
					'http://mississippiriverdelta.org/a-tale-of-two-basins-why-one-is-thriving-while-the-other-is-dying/',
			},
			{
				label:
					'A Cornerstone for Coastal Restoration: The Mid-Barataria Sediment Diversion',
				url:
					'http://mississippiriverdelta.org/a-cornerstone-for-coastal-restoration-the-mid-barataria-sediment-diversion/',
			},
			{
				label: 'Mid-Breton Sediment Diversion: What It Is and Why It’s Needed',
				url:
					'http://mississippiriverdelta.org/mid-breton-sediment-diversion-what-it-is-and-why-its-needed/',
			},
		],
	},
];

/**
 * Returns formatted copyright range.
 *
 * @author Deep Fried Advertising
 * @param  {Integer} start
 * @return {String}
 */
function copyright(start) {
	const current = new Date().getFullYear();
	const year = start && current > start ? `${start}–${current}` : current;

	return `${year}`;
}

/**
 * Copies URL to clipboard and displays success message.
 *
 * @author Deep Fried Advertising
 * @param  {Event} event
 * @return {undefined}
 */
function copyURL(event) {
	const button = event.target;
	const tracker = '?utm_source=footer-link';
	const url = `${button.dataset.copy}${tracker}`;
	const msg = document.createElement('span');

	msg.classList.add('Message');
	msg.innerHTML = 'Copied to clipboard!';

	copyStringToClipboard(url);

	// Display success message
	button.parentNode.insertBefore(msg, button.nextSibling);

	// Remove success message
	window.setTimeout(function() {
		button.nextSibling.remove();
	}, 1500);
}

class Footer extends React.Component {
	static propTypes = {
		clientName: PropTypes.string,
	};

	render() {
		const { className, clientName } = this.props;
		return (
			<footer id="footer" className={`Footer ${className}`}>
				<div className="Footer__main Container">
					<h2 className="Footer__title Title Title--1">
						<span className="TextColor--3">What You</span> Can Do
					</h2>
					<div className="Footer__content">
						<h3 className="Title Title--4">Share This Story</h3>
						<SocialLinks className={`Footer__social`} socialLinks={social} />
						<h3 className="Title Title--4">Copy URL</h3>
						<button
							className="Button Button--copy"
							onClick={copyURL}
							data-copy="ourfuturecoast.org"
						>
							ourfuturecoast.org
							<i className="fal fa-clone"></i>
						</button>
						<h3 className="Title Title--4">Learn More</h3>
						<nav className="Footer__menu">
							{footerMenus.map((menu, index) => (
								<div className="Footer__menu-items" key={index}>
									<h4 className="Title Title--5">{menu.title}</h4>
									<ul>
										{menu.links.map((link, index) => (
											<li key={index}>
												<a
													href={link.url}
													target="_blank"
													rel="noopener noreferrer"
												>
													{link.label}
												</a>
											</li>
										))}
									</ul>
								</div>
							))}
						</nav>
					</div>
				</div>
				<div className="Footer__credits">
					<div className="Footer__credits-inner">
						<h4 className="Title Title--4">Envisioning Our Future Coast</h4>
						<ul>
							<li>
								&copy; {clientName} {copyright(2020)}. Based on the{' '}
								<a
									target="_blank"
									rel="noopener noreferrer"
									href="https://www.waltonfamilyfoundation.org/learning/envisioning-the-future-of-the-louisiana-gulf-coast"
								>
									report by Dr. Donald Boesch
								</a>{' '}
								for{' '}
								<a
									target="_blank"
									rel="noopener noreferrer"
									href="https://www.waltonfamilyfoundation.org/our-work/environment/coastal-gulf-of-mexico"
								>
									the Walton Family Foundation
								</a>
								.
							</li>
							<li>
								Maps and illustrations created by
								<br />
								<a
									href="https://www.scapestudio.com/"
									target="_blank"
									rel="noopener noreferrer"
								>
									Scape Landscape Architecture DPC
								</a>
								.
							</li>
							<li>
								Additional material provided by
								<br />
								<a
									href="http://mississippiriverdelta.org/"
									target="_blank"
									rel="noopener noreferrer"
								>
									Restore the Mississippi River Delta
								</a>
								.
							</li>
							<li>
								Website created by
								<br />
								<a
									href="https://deepfried.com"
									target="_blank"
									rel="noopener noreferrer"
								>
									Deep Fried
								</a>
								.
							</li>
						</ul>
					</div>
				</div>
			</footer>
		);
	}
}

export default Footer;
