/**
 * Debounces event handlers.
 *
 * @author Deep Fried Advertising
 * @param  {Function}  func            Callback.
 * @param  {Number}  [wait=75]         Delay in ms.
 * @param  {Boolean} [immediate=false] Whether to fire before wait.
 * @return {Function}
 */
function debounce(func, wait = 75, immediate = false) {
	var timeout;

	return function() {
		var context = this;
		var args = arguments;
		var later = function() {
			timeout = null;
			if (!immediate) {
				func.apply(context, args);
			}
		};
		var callNow = immediate && !timeout;

		clearTimeout(timeout);

		timeout = setTimeout(later, wait);

		if (callNow) func.apply(context);
	};
}

/**
 * Toggles Nav visibility based on scroll direction.
 *
 * @author Deep Fried Advertising
 * @return {undefined}
 */
function watchScroll() {
	const scrollDirection = arguments[0].deltaY;
	const theNav = document.querySelector('.Wrap--main-nav');
	const navClass = theNav.classList.contains('navHide');

	if (scrollDirection > 0 && navClass === false) {
		theNav.classList.add('navHide');
	}
	if (scrollDirection < 0 && navClass === true) {
		theNav.classList.remove('navHide');
	}
}

/**
 * Adds scroll/wheel event listener.
 *
 * @author Deep Fried Advertising
 * @return {undefined}
 */
function scrollWatch() {
	if ('undefined' !== typeof window) {
		window.addEventListener('wheel', debounce(watchScroll));
	}
}

/**
 * Copies string to OS clipboard.
 *
 * @author Deep Fried Advertising
 * @param  {String} str
 * @return {undefined}
 */
function copyStringToClipboard(str) {
	var el = document.createElement('textarea');
	el.value = str;
	// Set non-editable to avoid focus and move outside of view
	el.setAttribute('readonly', '');
	el.style = { position: 'absolute', left: '-9999px' };

	document.body.appendChild(el);
	el.select();
	document.execCommand('copy');

	// Clean up
	document.body.removeChild(el);
}

function addBasinClass(theClass) {
	const basinMap = document.querySelector('.Container--basin-map');
	basinMap.setAttribute('data-basin', theClass);
}

/**
 * Gets active Basin Class
 * and adds class to Bain background Div
 *
 * @author Deep Fried Advertising
 * @return {undefined}
 */
function basinWatch() {
	let activeBasin = document.querySelector('.current-basin');
	if (null !== activeBasin) {
		const [activeBasinClasslist] = Array.from(activeBasin.classList);
		addBasinClass(activeBasinClasslist);
		return;
	}
	addBasinClass('off');
	return;
}

/**
 * Callback for IntersectionObserver.
 *
 * @author Deep Fried Advertising
 * @param  {Array} changes
 * @param  {[type]} observer [description]
 * @return {undefined}
 */
function elementInView(entries, observer) {
	entries.forEach(entry => {
		if (entry.isIntersecting) {
			entry.target.dataset.viewed = true;
		} else {
			entry.target.dataset.viewed = false;
		}
	});
}

/**
 * Attaches IntersectionObserver.
 *
 * @author Deep Fried Advertising
 * @return {undefined}
 */
function listenInView(callback = elementInView) {
	if ('undefined' === typeof document) {
		return;
	}

	let els = document.querySelectorAll('[data-section]');

	if (0 < els.length) {
		const config = {
			root: null,
			rootMargin: '0px',
			threshold: [0.25, 0.75],
		};

		if ('IntersectionObserver' in window) {
			const observer = new IntersectionObserver(callback, config);
			els.forEach(el => observer.observe(el));
		}
	}
}

/**
 * Toggles Class for mobile menu.
 *
 * @author Deep Fried Advertising
 * @param  {String}
 * @return {String}
 */
function classToggle(toggleButton, target, toggleClass) {
	toggleButton.addEventListener('click', () => {
		target.classList.toggle(toggleClass);
	});
}

export {
	scrollWatch,
	copyStringToClipboard,
	basinWatch,
	listenInView,
	classToggle,
	addBasinClass,
};
