/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import Footer from './Footer';
import ForReview from './ForReview';

const Layout = ({ children }) => {
	const { site } = useStaticQuery(graphql`
		query SiteTitleQuery {
			site {
				siteMetadata {
					author
				}
			}
		}
	`);

	return (
		<>
			<ForReview />
			<main>{children}</main>

			<Footer clientName={site.siteMetadata.author} />
		</>
	);
};

Layout.propTypes = {
	children: PropTypes.node.isRequired,
	errorPath: PropTypes.bool,
};
export default Layout;
